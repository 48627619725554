<template>
  <div class="clearStudent">
    <el-dialog
      title="清空考生"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="448px"
    >
      <div class="tips">
        <img src="@/assets/uExam/icon_waring.png" alt="" />
        1、清除考生将会完全删除学生答题卡及成绩
      </div>
      <!-- <div class="school-box">
        选择需要清空的学校 ：
        <el-select
          v-model="checkList"
          clearable
          filterable
          multiple
          collapse-tags
          placeholder="请选择"
          style="width: 235px"
        >
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div> -->

      <!-- schoolList -->
      <div class="model-title">选择需要清除考生的学校</div>
      <el-input
        v-model="keyword"
        placeholder="搜索"
        @keyup.enter.native="handleClick"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="handleClick"
        >
        </i>
      </el-input>
      <div class="check-list-box">
        <div class="title">
          <div>
            <span class="span-btn" @click="checkChange(1)">全选</span>
            <span class="span-btn" @click="checkChange(2)">反选</span>
          </div>
          <div class="text-box">
            已选
            <span>{{ checkList.length }}</span>
            所学校
          </div>
        </div>
        <div class="check-cnt edit-scroll-style">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(v, i) in schoolList"
              :key="i"
              :label="v.schoolId"
            >
              {{ v.schoolName }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="showPassword()">确 定</el-button>
      </span>
    </el-dialog>
    <passwordTips ref="passwordTips"></passwordTips>
  </div>
</template>

<script>
import passwordTips from "./passwordTips.vue";
import {
  // fetchList,
  // putObj,
  // addObj,
  // queryExamClass,
  // tkQuerypage,
  // examnewruleList,
  schoollistbyexamid,
  // getUnionQuerypage,
} from "@/core/api/exam/exam";
export default {
  name: "ClearStudent",
  components: { passwordTips },
  data() {
    return {
      dialogVisible: false,
      keyword: "",
      schoolList: [],
      checkList: [],
    };
  },
  created() {},
  methods: {
    succPass() {
      this.dialogVisible = false;
      this.$parent.getNewList();
    },
    showPassword() {
      if (this.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择学校",
          type: "warning",
        });
        return;
      }
      this.$refs.passwordTips.init();
    },
    checkChange(type) {
      if (type == 1) {
        let arr = this.schoolList.map((item) => item.schoolId);
        this.checkList = this.checkList.concat(arr);
        this.checkList = [...new Set(this.checkList)];
      }
      if (type == 2) {
        let arr = this.schoolList.map((item) => item.schoolId);
        // 过滤出原本应该有的 但是又不在当前显示列表的
        let array = this.checkList.filter((item) => !arr.includes(item));
        arr = arr.filter((item) => !this.checkList.includes(item));
        this.checkList = array.concat(arr);
      }
    },
    submit() {},
    handleClick() {
      this.schoolList = this.oldScohhoList.filter((item) => {
        if (
          !this.keyword ||
          (this.keyword &&
            item.schoolName &&
            item.schoolName.indexOf(this.keyword) != -1)
        ) {
          return item;
        }
      });
    },
    async init() {
      const res = await schoollistbyexamid({
        type: 1,
        examId: this.$route.query.examId,
      });
      this.checkList = [];
      let schoolList = res.data.data || [];
      this.oldScohhoList = schoolList.filter((item) => item.type == 1);
      this.schoolList = JSON.parse(JSON.stringify(this.oldScohhoList));
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.clearStudent {
  .check-list-box {
    width: 100%;
    border: 1px solid #dee0e7;
    margin-top: 18px;
    .check-cnt {
      padding: 18px;
      padding-top: 0;
      height: 322px;
      .list-box {
        .check-list {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
          i {
            cursor: pointer;
          }
        }
      }
      .el-checkbox {
        display: block;
        margin-top: 18px;
        margin-bottom: 0;
      }
    }
    .title {
      padding: 10px 18px;
      background: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      display: flex;
      justify-content: space-between;
      .text-box {
        span {
          color: #2474ed;
        }
      }

      .span-btn {
        margin-right: 18px;
        cursor: pointer;
      }
    }
  }
  .el-input {
    width: 214px;
  }
  .school-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tips {
    padding: 10px 18px;
    background: #fff3f3;
    color: #292626;
    margin-bottom: 18px;
  }
  .model-title {
    color: #2474ed;
    padding: 18px 0;
  }
}
</style>
