<template>
  <div class="ImportWizard">
    <el-dialog
      title="导入考生向导"
      :visible.sync="dialogVisible"
      width="693px"
      :close-on-click-modal="false"
    >
      <div class="item-introduce-box">
        <!-- <div class="item-introduce" @click="showImport(1)">
          <p class="title">批量新增考生</p>
          <p>
            1、班级名称务必统一，如“1”和“01”将会被认为是两个不同的班级。<br />
            2、相同年级的学生不允许考号重复。<br />
            *如考试模式为（新高考）模式，则需以下：<br />
            1、教学班级属于必填项，漏填会导致表格无法导入。<br />
            2、首选科目必须为物理或历史其中一科，漏填会导致表格无法导入。<br />
            3、再选科目务必填写两科，漏填或科目相同均会导致表格无法导入。<br />
          </p>
        </div> -->
        <div class="item-introduce" @click="showImport(2)">
          <p class="title">导入系统学生</p>
          <p>
            1、能够直接调用基础信息-学生管理中对应年级和指定班级的学生信息。<br />
            2、若系统中的学生考号为空，需先将考号补全，再执行导入功能；<br />
            3、若为新高考模式，则系统学生选考科目和教学班不得为空，需要符合3+1+2
            选考要求；
          </p>
        </div>
        <!-- <div class="item-introduce" @click="showImport(3)">
          <p class="title">复用参考考生</p>
          <p>
            1、能够将已参考的考生信息复用到当前新建考试中。<br />
            2、若已存在考生，需先清空列表，再进行复用
          </p>
        </div> -->
        <div class="item-introduce" @click="showImport(4)">
          <p class="title">批量修改考生</p>
          <p>
            1、可使用模板对考生除考号、学校以外的所有信息（包括:学籍号、姓名、班级、选考组合，选考科目、教学班、分类、是否参与统计）进行修改，再进行批量修改导入
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ImportWizard",
  components: {},
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    showImport(type) {
      this.dialogVisible = false;
      if (type == 1) {
        this.$parent.$refs.ImportExaminee.init();
      }
      if (type == 2) {
        this.$parent.$refs.ImportStudent.init();
      }
      if (type == 3) {
        this.$parent.$refs.reuseExaminee.init();
      }

      if (type == 4) {
        this.$parent.$refs.ImportModification.init();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.ImportWizard {
  .item-introduce-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 500;
      color: #0a1119;
      text-align: center;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }
  .item-introduce {
    padding: 8px;
    width: 208px;
    height: 356px;
    background: #f3f8ff;
    border-radius: 4px;
    border: 1px dashed #90b8f6;
    &:hover {
      border: 1px solid #2474ed;
      cursor: pointer;
    }
  }
}
</style>
