<template>
  <div class="candidateStatistics">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="width"
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="type" size="medium" @change="setWidth()">
        <el-radio-button :label="1">学校</el-radio-button>
        <el-radio-button v-if="ruleId > 0" :label="2">选考组合</el-radio-button>
      </el-radio-group>
      <p>
        共计 {{ schoolData.schoolNum }} 所学校，参考人数
        {{ schoolData.total }} 人，不参统人数
        {{ schoolData.excludeStatistics }} 人
      </p>
      <template v-if="ruleId > 0">
        <template v-if="type == 2">
          <el-table
            v-loading="listLoading"
            :data="eleccateList"
            border
            style="width: 100%"
          >
            <el-table-column prop="name" label="选考组合" align="center">
            </el-table-column>
            <el-table-column
              prop="total"
              label="考生人数"
              width="116"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="excludeStatistics"
              label="不参统人数"
              width="116"
              align="center"
            >
            </el-table-column>
          </el-table>
          <no-data
            v-if="eleccateList.length == 0"
            message="暂无考生信息，请添加"
          ></no-data>
        </template>
        <template v-if="type == 1">
          <el-table :data="tableData" border>
            <el-table-column prop="schoolName" label="学校名称" align="center">
            </el-table-column>
            <el-table-column
              prop="total"
              label="考生人数"
              width="116"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="excludeStatistics"
              label="不参统人数"
              width="116"
              align="center"
            >
            </el-table-column>
            <el-table-column
              v-for="(v, i) in statisticsData"
              :key="i"
              :label="v.subjectName"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ getClassNum(row, v, "number") }}
              </template>
            </el-table-column>
          </el-table>
          <no-data
            v-if="tableData.length == 0"
            message="暂无考生信息，请添加"
          ></no-data>
        </template>
      </template>
      <template v-if="ruleId == 0">
        <el-table
          v-loading="listLoading"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column prop="schoolName" label="学校名称" align="center">
          </el-table-column>
          <el-table-column
            prop="total"
            label="考生人数"
            width="116"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="excludeStatistics"
            label="不参统人数"
            width="116"
            align="center"
          >
          </el-table-column>
        </el-table>
        <no-data
          v-if="tableData.length == 0"
          message="暂无考生信息，请添加"
        ></no-data>
      </template>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
const typeList = [
  {
    label: "学校",
  },
  {
    label: "选考组合",
  },
];
import { studentnum } from "@/core/api/exam/examstudent";
export default {
  name: "CandidateStatistics",
  components: {},
  data() {
    return {
      tableData: [],
      eleccateList: [],
      schoolData: { schoolList: [] },
      dialogVisible: false,
      listLoading: false,
      typeList: typeList,
      type: "",
      width: "",
      title: "",
      ruleId: "",
      listMax: 0,
      statisticsData: [
        { subjectName: "历史", subjectId: 4, details: [], total: 0 },
        { subjectName: "物理", subjectId: 8, details: [], total: 0 },
        { subjectName: "地理", subjectId: 5, details: [], total: 0 },
        { subjectName: "政治", subjectId: 6, details: [], total: 0 },
        { subjectName: "化学", subjectId: 9, details: [], total: 0 },
        { subjectName: "生物", subjectId: 7, details: [], total: 0 },
      ],
    };
  },
  created() {},
  methods: {
    getClassNum(row, v) {
      let str = "";
      let { subjectId } = v;
      if (row.schoolId) {
        let { electiveInfoVOS } = row;
        electiveInfoVOS.map((item) => {
          if (item.subjectId == subjectId) {
            str = item.number;
          }
        });
      } else {
        str = 0;
        this.tableData.map((item) => {
          if (!item.electiveInfoVOS) {
            return;
          }
          item.electiveInfoVOS.map((index) => {
            if (index.subjectId == subjectId) {
              str = str + index.number;
            }
          });
        });
      }
      if (!str) {
        str = "-";
      }
      return str;
    },
    init() {
      this.dialogVisible = true;
      this.type = 1;
      this.ruleId = this.$parent.examInformation.ruleId;
      this.getStudentnum();
      this.setWidth();
    },
    getStudentnum() {
      studentnum({ examId: this.$route.query.examId })
        .then((res) => {
          this.schoolData = res.data.data;
          this.tableData = this.schoolData.schoolList || [];
          this.eleccateList = this.schoolData.eleccateList || [];
          if (this.ruleId > 0) {
            this.tableData.push({
              schoolName: "小计",
              excludeStatistics: this.schoolData.excludeStatistics,
              total: this.schoolData.total,
            });
          }
        })
        .catch(() => {});
    },
    setWidth() {
      if (this.ruleId == 0) {
        this.width = "472px";
        this.title = "考生统计";
      } else {
        // （新高考）
        this.title = "考生统计";
        if (this.type == 1) {
          this.width = "910px";
        } else {
          this.width = "472px";
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.candidateStatistics {
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #fff;
    color: #2474ed;
  }
}
</style>
