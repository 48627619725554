<template>
  <div class="importFile">
    <el-dialog
      title="批量添加考生"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div v-if="$parent.examInformation.ruleId == 0" class="tips">
            <div class="tips-title">【提示】</div>
            1、系统默认为全部学校，模板中必须填写学校名称，导入学校名称或简称必须与系统中留存的一致；<br />
            2、若已经选择指定学校，模板中则无需填写学校名称，导入时系统默认为已指定学校；<br />
            3、若出现“所选择导入学校”与模板中所填写学校名称不一致时，数据则无法导入；<br />
            4、班级名称务必统一，如“1”和“01”将会被认为是两个不同的班级相同年级；<br />
            5、相同学校的学生不允许考号重复；
          </div>
          <div v-if="$parent.examInformation.ruleId > 0" class="tips">
            <div class="tips-title">【提示】</div>
            1、系统默认为全部学校，模板中必须填写学校名称，且学校名称或简称必须与系统中留存的一致；<br />
            2、若已经选择指定学校，模板中则无需填写学校名称，导入时系统默认为已指定学校；<br />
            3、若出现“所选择导入学校”与模板中所填写学校名称不一致时，数据则无法导入；<br />
            4、教学班级属于必填项，漏填会导致表格无法导入；<br />
            5、首选科目必须为物理或历史其中一科，漏填会导致表格无法导入；<br />
            6、再选科目务必填写两科，漏填或科目相同均会导致表格无法导入；<br />
            7、班级名称务必统一，如“1”和“01”将会被认为是两个不同的班级；<br />
            8、相同年级、相同学校的学生不允许考号重复；
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              选择学校：
              <el-select
                v-model="schoolId"
                placeholder="请选择"
                clearable
                filterable
                @change="checkSchoolId()"
              >
                <el-option label="所有学校" value=""> </el-option>
                <el-option
                  v-for="item in schoolList"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <span class="down-tips" @click="downloadFile()">
                考生模版.xls
              </span>
            </div>
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      btnType: 0,
      schoolList: [],
      schoolId: "",
    };
  },
  created() {},
  methods: {
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    upLoadSucc(data) {
      this.data = data;
      setTimeout(() => {
        // this.dialogVisible = false;
        this.$parent.getDataList(1);
        this.$parent.getclassandexamroom();
      }, 2000);
    },
    downloadFile() {
      // //普通考试
      let url1 =
        "https://static.wtjy.com/template/%E8%81%94%E8%80%83%E6%96%B0%E9%AB%98%E8%80%83-%E8%80%83%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
      let url =
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E8%80%83%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF(%E6%96%B0).xls";

      if (this.$parent.examInformation.ruleId > 0) {
        url = url1;
      }

      fileDownloadByUrl(url, "考生导入模板");
    },
    checkSchoolId() {
      this.$refs.ElImportUpload.queryData = {
        appointSchoolId: this.schoolId,
      };
    },
    init() {
      this.dialogVisible = true;
      this.schoolList = this.$parent.schoolList;
      this.$nextTick(() => {
        let url = "/exam/examstudent/import/" + this.$route.query.examId;
        let accept = ".xlsx,.xls";

        this.$refs.ElImportUpload.queryData = {
          appointSchoolId: this.schoolId,
        };
        this.$refs.ElImportUpload.init(url, accept);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importFile {
}
</style>
