<template>
  <div class="useStudentBySchool">
    <p>
      <i class="el-icon-info"></i>
      注：按学校清除考生，会将该学校下所有考生清除，请谨慎操作！,不选学校将清空所有考生
    </p>
    <div class="header">
      <div class="search">
        搜索：<el-input
          v-model="search"
          style="width: 280px"
          placeholder="输入学校名称"
          @input="searchInput"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-input>
      </div>
      <div class="clean" @click="clean">取消选择</div>
    </div>
    <el-checkbox-group v-model="checkList" @change="handleChange">
      <el-checkbox
        v-for="(item, index) in schoolList"
        :key="index"
        :label="item.schoolId"
        >{{ item.schoolName }}</el-checkbox
      >
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: "UseStudentBySchool",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      checkList: [],
      schoolList: [],
    };
  },
  mounted() {
    this.schoolList = [...this.list];
  },
  methods: {
    searchInput() {
      if (this.search) {
        this.schoolList = this.list.filter(
          (item) => item.schoolName.indexOf(this.search) != -1
        );
      } else {
        this.schoolList = [...this.list];
      }
    },
    clean() {
      this.checkList = [];
      this.$emit("change", this.checkList);
    },
    handleChange() {
      this.$emit("change", this.checkList);
    },
  },
};
</script>
<style lang="scss" scoped>
.useStudentBySchool {
  p {
    i {
      color: #d92020;
      margin-right: 16px;
    }
    font-size: 14px;
    color: #605757;
    background: #fff3f3;
    border: 1px dashed #d92020;
    box-sizing: border-box;
    padding: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    .clean {
      font-size: 14px;
      color: #2474ed;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .el-checkbox {
    margin-right: 0;
    margin-bottom: 32px;
    width: 33%;
  }
}
</style>
