<template>
  <div class="ImportExaminee">
    <el-dialog
      title="考生复用"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="669px"
      :close-on-click-modal="false"
    >
      <div class="import-box">
        <div style="height: 425px" class="upload-box">
          <span class="export-box-go" @click="goBack">
            <i class="el-icon-back"></i>
            返回向导页
          </span>
          <el-steps
            style="height: 360px"
            direction="vertical"
            :active="active"
            finish-status="success"
          >
            <el-step title="选择考试类型">
              <template slot="description">
                <el-select
                  v-model="type"
                  filterable
                  placeholder="请选择考试类型"
                  @change="examlistbytype"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-step>
            <el-step title="选择考试" description="">
              <template slot="description">
                <el-select v-model="examId" filterable placeholder="请选择考试">
                  <el-option
                    v-for="item in examlistbytypeData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <div class="btn-box">
                  <el-button @click="goBack">取 消</el-button>
                  <el-button
                    type="primary"
                    :disabled="!examId"
                    :loading="modelLoading"
                    @click="submit()"
                  >
                    确 定
                  </el-button>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
        <div class="tips">
          <p style="color: #f56c6b">【注意】</p>
          <p>1、考生复用 将已创建过考试考生名单引用到当前考试。</p>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStore } from "@/core/util/store";
import {
  examlistbytype,
  saveexamstudentbyexam,
} from "@/core/api/exam/examstudent";
import { examTypeOptions } from "@/core/util/constdata";
export default {
  name: "ImportExaminee",
  components: {},
  data() {
    return {
      type: "",
      examId: "",
      examlistbytypeData: [],
      errorUrl: "",
      dialogVisible: false,
      modelLoading: false,
      btnLoading: false,
      active: 0,
      message: "",
      typeList: [{ value: "0", label: "全部" }].concat(examTypeOptions),
      //考生导入start
      examStudentImportURL: "",

      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
    };
  },
  created() {
    // this.examStudentModifyURL =
    //   "/exam/examstudent/modify/" + this.$route.query.examId;
  },
  methods: {
    // 考生复用
    submit() {
      let data = {
        currentExamId: this.examId,
        examId: this.$parent.examInformation.examId,
      };
      this.modelLoading = true;
      saveexamstudentbyexam(data)
        .then(() => {
          // modelLoading reuseStudentVisible
          this.modelLoading = false;
          this.dialogVisible = false;
          this.$message({
            message: "复用成功",
            type: "success",
            showClose: true,
          });
          this.$parent.getDataList();
        })
        .catch(() => {
          // console.log("惊了");
          this.modelLoading = false;
        });
    },
    // 获取考试列表
    examlistbytype() {
      this.examId = "";
      this.examlistbytypeData = [];
      this.active = 1;
      let data = {
        // gradeId: this.$parent.examInformation.gradeId,
        level: this.$parent.examInformation.level,
        year: this.$parent.examInformation.year,
        type: this.type,
        examId: this.$parent.examInformation.examId,
      };
      if (this.$parent.examInformation.examModel) {
        data.ruleId = 1;
      }
      examlistbytype(data).then((res) => {
        this.examlistbytypeData = res.data.data;
      });
    },
    handleClose(done) {
      this.$parent.$refs.ImportWizard.dialogVisible = true;

      done();
    },
    goBack() {
      this.dialogVisible = false;
      this.$parent.$refs.ImportWizard.dialogVisible = true;
    },
    init() {
      //   console.log("111111");
      this.dialogVisible = true;
      this.active = 0;
      this.type = "";
      this.examId = "";
    },
  },
};
</script>
<style lang="scss">
@import "@/sass/uploadFile/model.scss";
</style>
<style scoped lang="scss">
.ImportExaminee {
  .import-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .upload-box {
      width: calc(100% - 330px);
    }
    .btn-box {
      margin-top: 18px;
    }

    .tips {
      width: 330px;
      height: 425px;
      background: #fff5f0;
      border: 1px dashed #ffb692;
      padding: 10px;
      font-size: 12px;
    }
    > div {
      // height: 300px;
    }
    .export-box-go {
      cursor: pointer;
      color: #0a1119;
      // margin-bottom: 18px;
    }
    .el-steps {
      margin-top: 18px;
    }
  }
}
</style>
