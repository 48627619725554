<template>
  <div class="importFile">
    <el-dialog
      title="批量修改考生"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div class="tips">
            <div class="tips-title">【提示】</div>
            批量修改考生将修改除考号和学校以外的所有考生属性信息（包括学籍号、姓名、班级、考场、座位号）
            <br />
            2、请先使用导出功能导出需要修改的考生，然后在Excel中修改考生信息，使用批量修改功能导入Excel修改。
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";

export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      btnType: 0,
    };
  },
  created() {},
  methods: {
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    upLoadSucc(data) {
      this.data = data;
      setTimeout(() => {
        // this.dialogVisible = false;
        this.$parent.getDataList(1);
        this.$parent.getclassandexamroom();
      }, 2000);
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        let url = "/exam/examstudent/modify/" + this.$route.query.examId;
        let accept = ".xlsx,.xls";

        this.$refs.ElImportUpload.queryData = {
          appointSchoolId: "",
        };
        this.$refs.ElImportUpload.init(url, accept);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importFile {
}
</style>
