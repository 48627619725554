<template>
  <div class="pushExamStudent">
    <el-dialog title="推送考生到校" :visible.sync="dialogVisible" width="684px">
      <div class="btn-box">
        <el-button type="text" @click="check(1)">全选</el-button>
        <el-button type="text" @click="check(2)">反选</el-button>
      </div>
      <div class="check-box">
        <el-checkbox-group v-model="checkList">
          <el-checkbox
            v-for="(item, index) in schoolList"
            :key="index"
            :label="item.id"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  pushschoollist,
  examstudenttoschool,
} from "@/core/api/exam/examstudent";
export default {
  name: "PushExamStudent",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      checkList: [],
      schoolList: [],
    };
  },
  created() {},
  methods: {
    check(type) {
      // 全选
      if (type == 1) {
        this.checkList = this.schoolList.map((item) => item.id);
      }
      // 反选
      if (type == 2) {
        let arr = this.schoolList.map((item) => item.id);
        let checkList = [...this.checkList];
        this.checkList = arr.filter((item) => !checkList.includes(item));
      }
    },
    submit() {
      if (this.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择推送学校",
          type: "warning",
        });
        return;
      }
      let data = {
        examId: this.$route.query.examId,
        schoolIds: this.checkList.join(","),
      };
      this.pushSchool(data);
    },
    pushSchool(data) {
      this.btnLoading = true;
      examstudenttoschool(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "考生推送中，请稍后查看结果!",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async init() {
      await this.getSchoolList();
      this.dialogVisible = true;
    },
    async getSchoolList() {
      await pushschoollist({ examId: this.$route.query.examId })
        .then((res) => {
          this.checkList = [];
          this.schoolList = res.data.data;
          //   console.log(res);
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.btn-box {
  margin-bottom: 18px;
}
.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.el-checkbox {
  margin-right: 0;
  min-width: 33%;
  margin-bottom: 18px;
}
</style>
