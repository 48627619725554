<template>
  <div class="passwordTips">
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <div>
        此操作将永久删除
        <span class="err">
          {{ strArr }}
        </span>

        等学校考生,
        <span class="err">若已有扫描记录或已有阅卷记录</span>
        ，请先清空记录后再进行删除！
      </div>
      <div class="password">
        <span>请输入登录密码：</span>

        <el-input
          v-model="input"
          style="width: 0; height: 0; overflow: hidden"
          placeholder="请输入内容"
        ></el-input>
        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          @keyup.enter.native="monitorBack"
        />
      </div>
      <div class="tips">此操作不可撤销</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" :loading="modelLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { encryption } from "@/core/util/util";
import { clearByCondition } from "@/core/api/exam/examstudent";
export default {
  name: "PasswordTips",
  components: {},
  data() {
    return {
      modelLoading: false,
      dialogVisible: false,
      strArr: "",
      password: "",
      input: "",
    };
  },
  created() {},
  methods: {
    init() {
      this.password = "";
      // 具体情况具体分析 这个地方获取初始化页面需要的参数
      this.schoolList = this.$parent.oldScohhoList;
      this.checkList = this.$parent.checkList;
      let arr = [];
      this.schoolList.map((item) => {
        if (this.checkList.includes(item.schoolId)) {
          arr.push(item.schoolName);
        }
      });
      this.strArr = arr.join("、");
      this.dialogVisible = true;
      //   处理好需要提交的数据
      this.subData = { schoolIds: this.checkList.join(",") };
    },
    deletelint(data) {
      this.modelLoading = true;
      Object.assign(data, this.subData);
      clearByCondition(this.$route.query.examId, data)
        .then(() => {
          this.modelLoading = false;
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: "清空成功",
            type: "success",
          });
          this.$parent.succPass();
        })
        .catch(() => {
          this.modelLoading = false;
        });
    },
    monitorBack() {
      if (!this.password) {
        this.$message({
          showClose: true,
          message: "请输入密码！",
          type: "warning",
        });
        return;
      }

      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        password: user.password,
      };
      this.deletelint(data);
    },
  },
};
</script>
<style scoped lang="scss">
.passwordTips {
  .ant-input-affix-wrapper {
    width: auto;
  }
  .tips {
    text-align: center;
    margin-top: 12px;
  }
  .err {
    color: #f57574;
  }
  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
  }
}
</style>
